import React from 'react';
import cn from 'classnames';

import './Loader.scss';

export function LoaderWrap(props) {
	return (
		<div className='loader__wrap'>
			{props.children}
		</div>
	)
}

export default function Loader({ size = '40px', sm }) {
	return (
		<div className={cn('loader', sm && 'loader_sm')}>
			<svg x="0px" y="0px" width={size} height={size} viewBox="0 0 50 50" xmlSpace="preserve">
				<path fill="#000" d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z">
					<animateTransform attributeType="xml"
					  attributeName="transform"
					  type="rotate"
					  from="0 25 25"
					  to="360 25 25"
					  dur="0.6s"
					  repeatCount="indefinite"/>
				</path>
		  </svg>
		</div>
	)
}
