import React from 'react';

import { BemHelper } from '../utils';

import './Icons.scss';

const iconClasses = BemHelper('icon');
const badgeClasses = BemHelper('badge');

export function IconCursor({ direction = 'right', ...rest }) {
	return (
		<span {...iconClasses(``, `cursor cursor_${direction}`)} />
	)
}

export function IconPlus({ color = 'white' }) {
	return (
		<span {...iconClasses(``, `plus ${color}`)} />
	)
}

export function Icon(props) {
	return (
		<span {...iconClasses('', props.type, props.extra || '')} onClick={props.onClick} />
	)
}

export function Badge(props) {
	return (
		<span {...badgeClasses('', props.type)}>{props.value}</span>
	)
}
