import React from 'react'
import { NavLink } from 'react-router-dom'

import Loader from './Loader'
import { BemHelper } from '../utils'

import './Buttons.scss'

const classes = BemHelper('button')

export function NavButton ({link, color, icon, children, ...props}) {
  if (link) {
    return (
      <NavLink
        to={link}
        {...classes('', color)}
      >
        {icon && icon}
        <span {...classes('text')}>{children}</span>
      </NavLink>
    )
  }
}

export function Button ({mod, icon, children, badge, loading, to, strong, disabled, ...rest}) {
  if (to) {
    return (
      <NavLink
        to={to}
        {...classes('', mod)}
      >
        {strong ? (
          <strong>{children}</strong>
        ) : (
          {children}
        )}
      </NavLink>
    )

  }

  return (
    <button
      disabled={loading || disabled}
      {...classes('', mod)}
      {...rest}
    >
      {icon && (
        <span {...classes('icon')}>{icon}</span>
      )}

      {loading ? (
        <Loader sm size='20px'/>
      ) : (
        <span {...classes('text', {strong})}>
					{children}
				</span>
      )}
      {badge && badge}
    </button>
  )
}
